<template>
  <div class="useracquisition">
    <UserAcquisitionHeader/>
    <AboutBayer/>
    <ClientsJourney/>
    <Advantages/>
    <Clients/>
    <FormSection/>
  </div>
</template>

<script>
import UserAcquisitionHeader from "@/components/UserAcquisitionHeader";
import AboutBayer from "@/components/AboutBayer";
import Advantages from "@/components/Advantages";
import ClientsJourney from "@/components/ClientsJourney";
import Clients from "@/components/Clients";
import FormSection from "@/components/FormSection";

export default {
  name: "UserAcquisition",
  components: {Clients, ClientsJourney, Advantages, AboutBayer, UserAcquisitionHeader, FormSection,},
  metaInfo: {
    title: 'User Acquisition - Monterizo',
    /*meta: [{
      vmid: 'description',
      name: 'description',
      content: "We Work With High Quality Traffic Sources that Generate the Maximum Benefit to Our Clients ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],*/
  }
}
</script>

<style lang="scss">
.useracquisition {
  padding: 3.9vw 0 0;

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 13.1vw 0 0;
  }
}
</style>