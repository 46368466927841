<template>
  <div class="AboutBayer">
    <div class="AboutBayer-Text">
      We work with high quality traffic sources<br>
      that generate the maximum benefit to our clients — <br>
      <span>
        high LTV and retention rates
      </span>
    </div>
    <div class="AboutBayer-Infos">
      <div
          v-for="(info, $info) in infos"
          :key="$info"
          class="AboutBayer-Info"
      >
        <div
            class="AboutBayer-InfoAmount"
        >
          {{ info.amount }}
        </div>
        <p
            class="AboutBayer-InfoText"
            v-html="info.text"
        ></p>
      </div>
    </div>
    <div class="Wrapper">
      <Partners
          :partners-title="'our sources'"
          :slider-info="sources" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Partners from "@/components/Partners";

export default {
  name: "AboutBayer",
  components: {Partners},
  data() {
    return {
      infos: [
        {
          amount: "30+",
          text: "DIRECT TRAFFIC SOURCES"
        },
        {
          amount: "30+",
          text: "MILLION APP DOWNLOADS"
        },
        {
          amount: "70+",
          text: "COUNTRIES",
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      sources: "getSources"
    })
  }
}
</script>

<style lang="scss">
.AboutBayer {
  background-color: var(--color-body1);

  .Wrapper {
    .mobile &,
    .landscape &,
    .portrait & {
      width: 94%;
    }
  }

  &-Text {
    margin-bottom: 3%;
    font-weight: 300;
    font-size: 1.823vw;
    line-height: 130%;
    text-align: center;
    color: var(--color-text-main1-light);

    span {
      color: var(--color-text-main2);
    }

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 7%;
      font-size: 2.8vw;
    }
  }

  &-Infos {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-bottom: 5%;
    text-transform: uppercase;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      align-items: flex-start;
    }
  }

  &-Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mobile &,
    .landscape &,
    .portrait & {
      flex: 0 0 29%;
      text-align: center;
    }
  }

  &-InfoAmount {
    font-size: 3.646vw;
    line-height: 1.5;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 5.842vw;
      line-height: 1;
    }
  }

  &-InfoText {
    font-size: 1.823vw;
    line-height: 1.5;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.804vw;
    }
  }
}

</style>