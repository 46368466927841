<template>
  <section class="ClientsJourney">
    <img :src="require(`@/assets/images/${switchBgImage}`)" alt="bg" class="ClientsJourney-Bg">
    <h3 class="ClientsJourney-Title">
      Customer Journey
    </h3>
    <ul class="ClientsJourney-List">
      <li
          v-for="(item, $item) in clientsJourney"
          :key="$item"
          class="ClientsJourney-Item"
          :class="item.class"
          v-html="item.text"
      ></li>
    </ul>
    <p class="ClientsJourney-Text">
      We attract high quality traffic
    </p>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ClientsJourney",
  computed: {
    ...mapGetters({
      clientsJourney: "getClientsJourney",
      isMobile: 'isMobile',
    }),
    switchBgImage() {
      return this.isMobile ? 'space-bg.png' : 'space-bg.png'
    }
  }
}
</script>

<style lang="scss" >
.ClientsJourney {
  position: relative;
  height: 62.657vw;
  padding: 10.573vw 0 0;
  counter-reset: list;
  background-color: var(--color-body1);

  .mobile &,
  .landscape &,
  .portrait & {
    height: 66.6vw;
  }

  &-Bg {
    position: absolute;
    top: .19vw;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-Title {
    position: absolute;
    top: 20%;
    left: 46%;
    z-index: 3;
    font-weight: 300;
    font-size: 3.386vw;
    color: var(--color-text-main);

    .mobile &,
    .landscape &,
    .portrait & {
      left: 44%;
      font-size: 4.206vw;
      text-align: left;
    }
  }

  &-Item {
    position: absolute;
    padding: 0 0 1.458vw;
    font-size: 1.563vw;
    line-height: 1.1;
    text-align: center;
    color: var(--color-text-main2);
    z-index: 3;

    &:first-child {
      padding: 0;
      &:after {
        display: none;
      }
    }

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2vw;
    }
  }

  .first {
    width: 11.36vw;
    left: 14.2vw;
    bottom: 28vw;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 12.36vw;
      left: 14.2vw;
      bottom: 28vw;
    }
  }

  .second {
    width: 13vw;
    left: 10.2vw;
    bottom: 16.7vw;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 15vw;
      left: 10.2vw;
      bottom: 16.7vw;
    }
  }

  .third {
    left: 6.1vw;
    bottom: 10vw;

    .mobile &,
    .landscape &,
    .portrait & {
      left: 4.1vw;
      bottom: 10vw;
    }
  }

  .fourth {
    width: 18vw;
    left: 9.1vw;
    bottom: 1vw;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 36vw;
      left: 1.1vw;
      bottom: 2vw;
    }
  }

  &-Text {
    position: absolute;
    z-index: 3;
    bottom: 5.5%;
    left: 45.4%;
    font-weight: 400;
    font-size: 2.083vw;
    line-height: 1.1;
    text-align: center;
    color: var(--color-text-main);

    .mobile &,
    .landscape &,
    .portrait & {
      bottom: 6.5%;
      left: 43.5%;
      font-size: 2.804vw;
    }
  }

  &-Img {
    position: absolute;
    z-index: 3;
    width: 15.63vw;
    left: percentage(205 / 1920);
    bottom: percentage(355 / 1920);
  }

}
</style>