<template>
  <section class="UserAcquisitionHeader">
    <img :src="require(`@/assets/images/${switchBgImage}`)" alt="bg" class="UserAcquisitionHeader-Bg">
    <h1 class="UserAcquisitionHeader-Title">
      user
      <span class="UserAcquisitionHeader-Subtitle">
       acquisition
      </span>
    </h1>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserAcquisitionHeader",
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
    switchBgImage() {
      return this.isMobile ? 'user-acquasition-bg.png' : 'user-acquasition-bg.png'
    }
  },
}
</script>

<style lang="scss">
.UserAcquisitionHeader {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 44.271vw;
  background-color: var(--color-body1);

  &-Bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-Title {
    position: absolute;
    z-index: 2;
    top: 22%;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: 900;
    font-size: 11.927vw;
    line-height: .8;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--color-text-main1);

    .mobile &,
    .landscape &,
    .portrait & {
      top: 20%;
      font-size: 15.6vw;
    }
  }

  &-Subtitle {
    display: block;
    font-size: 8.281vw;
    line-height: .95;
    color: var(--color-text-main);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 9.6vw;
    }
  }
}
</style>