<template>
  <section class="Clients">
    <img src="@/assets/images/clients-bg-left.png" alt="" class="Clients-Bg BgLeft">
    <img src="@/assets/images/clients-bg-right.png" alt="" class="Clients-Bg BgRight">
    <h3 class="Clients-Title">
      Our clients
    </h3>
    <div class="Wrapper Clients-Wrapper">
      <swiper
          :options="options"
          class="Clients-Slider"
      >
        <swiper-slide
            v-for="(client, i) in clients"
            :key="i"
            class="Clients-Slide"
        >
          <div class="Clients-SlideContent">
            <div class="Clients-SlideLeft">
              <img
                  v-if="client.left"
                  :src="require(`@/assets/images/clients/${client.left}`)"
                  alt="client icon"
                  class="Clients-SlideImg"
              >
            </div>
            <div class="Clients-SlideRight">
              <img
                  v-for="(icon, $icon) in client.right"
                  :key="'A' + $icon"
                  :src="require(`@/assets/images/clients/${icon}`)"
                  alt="client icon"
                  class="Clients-SlideImg"
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <svg width="81" height="63" class="Clients-SlideArrow Clients-SlidePrev" slot="button-prev">
        <use xlink:href="@/assets/images/icons-set.svg#double-chevron-left"></use>
      </svg>
      <svg width="81" height="63" class="Clients-SlideArrow Clients-SlideNext" slot="button-next">
        <use xlink:href="@/assets/images/icons-set.svg#double-chevron-right"></use>
      </svg>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {Navigation} from "swiper";

export default {
  name: "Clients",
  data() {
    return {
      options: {
        modules: [Navigation],
        slidesPerView: 'auto',
        speed: 700,
        cssMode: true,
        navigation: {
          nextEl: '.Clients-SlideNext',
          prevEl: '.Clients-SlidePrev'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      clients: "getClients",
      isMobile: 'isMobile'
    })
  }
}
</script>

<style lang="scss">
.Clients {
  padding: 10.42vw 0 0;
  background-image: url(~@/assets/images/clients-slider-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  position: relative;

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 10.42vw 0 4.5vw;
    background-image: url(~@/assets/images/clients-slider-bg-mobile.png);
    background-position: bottom center;
  }

  &-Bg {
    position: absolute;
    top: -6.4vw;
    z-index: -1;
  }

  .BgLeft {
    left: 0;
    z-index: -2;
  }
  .BgRight {
    right: 0;
  }

  &-Wrapper {
    position: relative;
    overflow-x: hidden;
    padding: 0 0 1.563vw;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 86%;
    }
  }

  &-Title {
    margin-bottom: 4.583vw;
    font-weight: 300;
    font-size: 3.385vw;
    text-align: center;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 5.374vw;
      font-size: 4.206vw;
    }
  }

  &-Slider {
    width: 66.917vw;
    margin: 0 auto;
    overflow-x: hidden;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 42.917vw;
    }
  }

  &-Slide {
    width: 23.125vw !important;
    margin: 0 3vw;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      width: 23.125vw !important;
      margin: 0 3vw;
    }
  }

  &-SlideContent {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 6.26vw;

      .mobile &,
      .landscape &,
      .portrait & {
        width: 6.26vw;
      }
    }
  }

  &-SlideLeft {
    margin-right: 8.625vw;
  }

  &-SlideRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      &:last-child {
        margin-top: 3.646vw;

        .mobile &,
        .landscape &,
        .portrait & {
          margin-top: 4.646vw;
        }
      }
    }

    .mobile &,
    .landscape &,
    .portrait & {
      //margin-right: 15.188vw;
    }
  }

  &-SlideArrow {
    position: absolute;
    width: 4.2vw;
    top: 45%;
    transform: translate(0, -50%);
    cursor: pointer;
    stroke: var(--color-text-main2);

    .desktop & {
      &:hover {
        stroke: var(--color-text-main1-light);
      }
    }

    .mobile &,
    .landscape &,
    .portrait & {
      top: 50%;
      width: 6.08vw;

      &:active {
        stroke: var(--color-text-main1-light);
      }
    }
  }

  &-SlidePrev {
    left: 0;
  }

  &-SlideNext {
    right: 0;
  }
}
</style>