<template>
  <section class="Advantages">
    <div class="Wrapper">
      <h3 class="Advantages-Title">
        Our advantages
      </h3>
      <div class="Advantages-Cards">
        <div
            v-for="(advantage, $advantage) in advantages"
            :key="$advantage"
            class="Advantages-Card"
            :class="advantage.class"
        >
          <img
              :src="require(`@/assets/images/${advantage.icon}`)"
              alt="icon"
              class="Advantages-CardIcon"
          >
          <div class="Advantages-CardTitle">
            {{ advantage.title }}
          </div>
          <p
              class="Advantages-CardText"
              v-html="advantage.text"
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Advantages",
  computed: {
    ...mapGetters({
      advantages: "getAdvantages"
    })
  }
}
</script>

<style lang="scss">
.Advantages {
  padding: 4.74vw 0 2.084vw;

  &-Title {
    margin-bottom: 3.438vw;
    font-weight: 300;
    font-size: 3.125vw;
    text-align: center;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 5.374vw;
      font-size: 4.206vw;
    }
  }

  &-Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    .mobile &,
    .landscape &,
    .portrait & {
      align-items: flex-start;
    }
  }

  &-Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25vw;
    text-align: center;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 30.84vw;
    }
  }

  .automation, .creativity, .anti-fraud {
    margin-bottom: 2.605vw;
  }
  .automation {
    img {
      width: 5.157vw;

      .mobile &,
      .landscape &,
      .portrait & {
        width: 8.2vw;
      }
    }
  }
  .creativity {
    img {
      width: 6.72vw;

      .mobile &,
      .landscape &,
      .portrait & {
        width: 9.4vw;
      }
    }
  }

  .anti-fraud {
    img {
      width: 5.573vw;

      .mobile &,
      .landscape &,
      .portrait & {
        width: 8.2vw;
      }
    }
  }

  .quality {
    img {
      width: 5.521vw;

      .mobile &,
      .landscape &,
      .portrait & {
        width: 9.4vw;
      }
    }
  }
  .client {
    img {
      width: 5.104vw;

      .mobile &,
      .landscape &,
      .portrait & {
        width: 9.4vw;
      }
    }
  }

  &-CardIcon {
    margin-bottom: 1.563vw;
  }

  &-CardTitle {
    font-size: 1.823vw;
    text-transform: uppercase;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 3.28vw;
    }
  }

  &-CardText {
    font-size: 1.3vw;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.81vw;
    }
  }
}
</style>